import React, { useCallback, useState } from 'react';
import { Section } from '../components/section';
import { sections } from '../hooks/use-section-analytics';
import { Tooltip } from '../components/tooltip';
import { images } from '../constants/images';
import Sprite from '../sprites/sprite';
import { Testimonial, testimonials } from '../components/testimonial/testimonial';
import { Modal } from '../components/policy-modal';
import { AmplitudeEvent, ButtonTag, analyticsState } from '../state/amplitude';
import { useMessages } from '../i18n/useMessages';

const certifications = [
  {
    src: images['ycb'],
    alt: 'Certified by the Yoga Certification Board of Ministry of Ayush',
    title: 'Yoga Certification Board, Ministry of Ayush',
    className: 'max-h-16',
  },
  {
    src: images['mot-medal'],
    alt: 'Certified as an Inbound Tour Operator by the Ministry of Tourism India',
    title: 'Inbound Tour Operator, India',
    className: 'max-h-16',
  },
  {
    src: images['incin-logo'],
    alt: 'Certified under Incredible India',
    title: 'Incredible India',
    className: 'max-h-10',
  },
  {
    src: images['icc'],
    title: 'Indian Chamber of Commerce',
    alt: 'Certified by the Indian Chamber of Commerce',
    className: 'max-h-16',
  },
  {
    src: images['iato'],
    alt: 'Certified by the Indian Association of Tour Operators',
    title: 'Indian Association of Tour Operators, India',
    className: 'max-h-16',
  },
  {
    src: images['mot-govin'],
    alt: 'Certified by the Ministry of Tourism India',
    title: 'Ministry of Tourism, Government of India',
    className: 'max-h-16',
  },
];

const firstColumn = [images['gallery-1'], images['gallery-4'], images['gallery-7']];
const secondColumn = [images['gallery-2'], images['gallery-5'], images['gallery-8']];
const thirdColumn = [images['gallery-3'], images['gallery-6']];
const columns = [firstColumn, secondColumn, thirdColumn];

export const WeLoveKumbhSection = () => {
  const [playing, setPlaying] = useState(false);

  const [videoSrc, setVideoSrc] = useState<string | null>(null);
  const onClick = useCallback((src: string, authorName: string) => {
    setIsOpen(true);
    setVideoSrc(src);
    analyticsState.amplitude?.track(AmplitudeEvent.BUTTON_CLICK, {
      buttonSource: ButtonTag.PLAY_TESTIMONIAL,
      testimonialAuthor: authorName,
    });
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onClickPlay = useCallback(() => {
    setPlaying(true);
    analyticsState.amplitude?.track(AmplitudeEvent.BUTTON_CLICK, {
      buttonSource: ButtonTag.PLAY_TESTIMONIAL_MONTAGE,
    });
  }, []);

  const getMessage = useMessages();
  const decadeOldStory = getMessage('camp67-we-love-kumbh.decade-old');
  const weKnowAnd = getMessage('camp67-we-love-kumbh.we-know-and');
  const loveKumbh = getMessage('camp67-we-love-kumbh.love');
  const rtoHosts = getMessage('camp67-we-love-kumbh.rto-kumbh-hosts');
  const moments2019 = getMessage('camp67-we-love-kumbh.moments-kumbh-2019');
  const internationalVisitors = getMessage('camp67-we-love-kumbh.hear-from-visitors');
  const seeMoreOn = getMessage('camp67-link.see-more-on');

  return (
    <>
      <VideoModal videoSrc={videoSrc} isOpen={isOpen} onClose={onClose} />
      <Section className="w-full m-auto mt-12 pb-9 bg-white" id={sections['weLoveKumbh']}>
        <ul className="flex flex-wrap md:flex md:flex-row list-none gap-8 items-center justify-center">
          {certifications.map(({ title, src, alt, className }, index) => (
            <li key={index} className="flex flex-col">
              <Tooltip text={title}>
                <img
                  src={src}
                  alt={alt}
                  className={`h-20 object-contain ${className} select-none pointer-events-none`}
                />
              </Tooltip>
            </li>
          ))}
        </ul>
        <div className="mt-12 h-1 border-b border-dashed border-b-gray-200 w-[75%] m-auto bg-transparent accent-transparent" />
      </Section>
      <Section
        className="mt-4 sm:mt-16 w-full m-auto relative px-6 lg:px-44"
        id={sections['galleryWelcome']}
      >
        <Sprite
          name="welcome-arrow"
          width={110}
          height={135}
          className="hidden sm:block w-16 h-20 -top-32 md:w-20 lg:w-32 lg:h-32 absolute left-10 lg:left-20 md:-top-6"
        />
        <hgroup>
          <h2 className="text-sm font-sans uppercase text-gray-600 font-bold tracking-widest xl:text-base text-center">
            {decadeOldStory}
          </h2>
          <h3 className="text-center text-2xl leading-5 md:text-4xl md:leading-6 xl:text-5xl xl:leading-8 text-green-300 font-display mt-9">
            {weKnowAnd} <u>{loveKumbh}</u> Kumbh
          </h3>
        </hgroup>
        <p className="mt-6 w-full max-w-120 md:max-w-130 mx-auto md:mx-auto md:w-130 text-center text-lg xl:text-xl xl:mt-6 leading-snug font-sans">
          {rtoHosts}
        </p>
        <div className="w-[1008px] max-w-[95%] m-auto mt-8">
          {playing ? (
            <iframe
              className="w-full md:min-w-[1008px] h-[480px]"
              src="https://www.youtube.com/embed/XUIs_7__LKY?si=Z2JzV5kMyAfPeIhl&autoplay=1&loop=1&autopause=0&muted=1"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          ) : (
            <div className="w-full h-[480px] relative">
              <img
                className="w-full h-full object-cover"
                src={images['testimonials-thumbnail']}
                alt="English testimonials thumbnail of Claudia, from Italy"
              />
              <Sprite
                name="play"
                width="64"
                height="64"
                className="cursor-pointer absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                onClick={onClickPlay}
              />
              <div className="absolute w-full px-5 left-1/2 text-white text-center -translate-x-1/2 bottom-32 md:bottom-28">
                <p className="font-serif font-bold text-2xl md:text-3xl">{moments2019}</p>
                <p className="text-sm md:text-base">{internationalVisitors}</p>
              </div>
            </div>
          )}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 items-center w-fit m-auto gap-x-8 gap-y-10 justify-center mt-12">
          {testimonials.map((testimonial, index) => (
            <Testimonial
              {...testimonial}
              onClick={() =>
                onClick(testimonial.author.videoSrc, testimonial.author.name)
              }
              key={index}
            />
          ))}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2 m-auto mt-12">
          {columns.map((column, index) => (
            <ul key={index} className="flex flex-col gap-2">
              {column.map((src, key) => (
                <li key={key}>
                  <img
                    src={src}
                    className="object-cover w-full rounded-lg select-none pointer-events-none"
                    alt=""
                  />
                </li>
              ))}
            </ul>
          ))}
        </div>
        <p className="text-gray-600 mt-4 md:mt-8 text-center md:text-left">
          {seeMoreOn}{' '}
          <a className="text-blue-500 underline" href="https://kumbhsukrit.com">
            kumbhsukrit.com →
          </a>
        </p>
      </Section>
    </>
  );
};

interface VideoModalProps {
  videoSrc: string | null;
  isOpen: boolean;
  onClose: VoidFunction;
}

const VideoModal: React.FC<VideoModalProps> = ({ videoSrc, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <iframe
        className="max-w-[95%] w-[1008px] h-[480px]"
        src={`${videoSrc}&autoplay=1&loop=1&autopause=0&muted=1` ?? ''}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      />
    </Modal>
  );
};
